import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import * as axios from '@/libs/axios'
import GloablUrl from './api/GloablUrl'
import 'fullpage.js/vendors/scrolloverflow';
import VueFullPage from 'vue-fullpage.js';
import 'lib-flexible/flexible'
Vue.use(VueFullPage);
Vue.prototype.PX_Get = axios.PX_Get
Vue.prototype.PX_Post = axios.PX_Post
Vue.prototype.PX_Put = axios.PX_Put
Vue.prototype.PX_Delete = axios.PX_Delete
Vue.prototype.GloablUrl = GloablUrl
Vue.$httpRequestList=[];
Vue.config.productionTip = false
Vue.use(ElementUI)
import VueAMap from 'vue-amap';
Vue.use(VueAMap);
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)
VueAMap.initAMapApiLoader({
  //申请地址 https://lbs.amap.com/ 选择web端jsAPI
  key: 'cc745e7aef7c30ba83785dba773827aa', 
  // 插件集合，用到什么插件就使用什么插件
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  v: '1.4.4', // 高德sdk版本，最新的sdk已经出到了2.0
  //官网解释：JSAPI 2.0 提供的开发接口与 1.4 版本达到 99%的兼容度，但是为了保证插件的稳定性我们还是选择1.4.4。
}) 
import * as  animate from 'animate.css'
Vue.use(animate)



import 'wowjs/css/libs/animate.css'
import wow from 'wowjs'
Vue.prototype.$wow = wow
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
