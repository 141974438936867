<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import index from './views/index/index.vue'
export default {
  name: 'App',
  components: {
    index
  }
}
</script>

<style>
 #app{
    margin: 0 ;
    padding: 0;
  }
  body,html{
    margin: 0;
    padding: 0;
    font-family: Source Han Sans CN;
    overflow-x:hidden;
  }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

ul{
  list-style: none;
}

.el-textarea__inner{
    resize: none !important;
}

/* @font-face {
    font-family: 'Source Han Sans CN';
    font-display: auto;
    src: url('../src/assets/Fonts/SourceHanSansCN-Regular.otf') ;
} */

.el-radio__label{
  white-space: normal !important;
  text-overflow: ellipsis !important;
  line-height: 18px;
  vertical-align: middle;
  display: inline-block;
}

.baberrage-item .normal .baberrage-avatar{
  display: none;
}
.el-message{
  z-index: 9999999999 !important;
}
.baberrage-item .baberrage-msg{
  padding-left: 0 !important;
}
@media screen  and (max-width: 900px){
  .el-message__content{
    font-size: 20px !important;
  }
}
</style>
