
import axios from 'axios';
import GloablUrl from '@/api/GloablUrl';
import router from '../router/index'
import Vue from 'vue'
// import Cookies from 'js-cookie'
import { Message } from 'element-ui';
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

//创建实例的作用是，可以让将baseUrl拼接在url的前面，给axios设置默认值
const instance = axios.create({
    baseURL: GloablUrl.baseURL,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

instance.interceptors.request.use(function (config) {
    config['cancelToken'] = new CancelToken(function executor(cancel) {
        Vue.$httpRequestList.push(cancel) //存储cancle
    })
    // config.headers.token = Cookies.get('vue_admin_template_token');
    return config
}, function (error) {
    return Promise.reject(error)
});

let messageError = ''
instance.interceptors.response.use(function (response) {
    if (response.data.code == 2001) {
        if(!messageError) {
            messageError = 'token失效，请重新登录'
            Message.error(messageError);
        }
        source.cancel()
        Cookies.set('vue_admin_template_token','')
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("name");
        window.localStorage.removeItem("user_number");
        window.localStorage.removeItem("id");
        window.localStorage.removeItem("img");
        window.localStorage.removeItem('collect')
        window.localStorage.removeItem('praise')
        router.push('/login')
        return  
    }
    if (response.data.code > 10000) {
    } else if (response.data.code != 200 && response.data.code < 10000) {
        let content = StatusCode[response.data.code] == undefined ?
            '状态码:' + response.data.code + '\n错误信息：错误状态码未定义' :
            '状态码:' + response.data.code + '\n错误信息：' + StatusCode[response.data.code];

        console.log("%c服务器返回错误信息：\n" +
            "接口地址：" + response.request.responseURL.replace(GloablUrl.baseURL, '') + "\n" +
            content,
            "color:red;font-size:20px;");
    } else {
        messageError = ''
    }
    //}
    return response;
}, function (error) {
    if(error.message === 'interrupt') {
        return new Promise(() => {});
      }
    return Promise.reject(error);
});

export function PX_Get(url, params, thenFunction) {
    if (typeof params == 'function') {
        thenFunction = params;
        params = {};
    }
    instance.get(url, params).then(thenFunction);
}

export function PX_Post(url, params, thenFunction) {
    if (typeof params == 'function') {
        thenFunction = params;
        params = {};
    }
    // var param = new URLSearchParams();
    // for(var key in params) param.append(key, params[key]);
    instance.post(url, params).then(thenFunction);
}

export function PX_Put(url, params, thenFunction) {
    if (typeof params == 'function') {
        thenFunction = params;
        params = {};
    }
    instance.put(GloablUrl.baseURL + url, params).then(thenFunction);
}

export function PX_Delete(url, params, thenFunction) {
    if (typeof params == 'function') {
        thenFunction = params;
        params = {};
    }
    instance.delete(GloablUrl.baseURL + url, { data: params }).then(thenFunction);
}
