<template>
  <div>
    <div class="top_header">
      <!-- <div class="header" id="headers">
        <div class="header_left">
          <div class="left_logo">
            <img src="../../assets/new_IMG/logo.png" alt="" />
          </div>
          <div class="right_line"></div>
        </div>
        <div class="nav">
          <ul class="nav_items">
            <li class="nav_item">首页</li>
            <li class="nav_item" @click="regard" @mouseover="showPop"       @mouseleave="outPop">关于盘相
              <div class="pop-up" v-if="pop_show" >
                <div class="up_nav" @click="regard">企业概况</div>
                <div class="up_nav" @click.stop="culture">企业文化</div>
                <div class="up_nav" @click.stop="qualification">资质荣誉</div>
                <div class="up_nav" @click.stop="journalism">新闻动态</div>
                <div class="up_nav" @click.stop="manpower">人力资源</div>
              </div>
            </li>
            <li class="nav_item" @click="product">产品中心</li>
            <li class="nav_item" @click="serve" @mouseover="serve_POP_show"       @mouseleave="serve_out">服务支持
              <div class="pop-serve" v-if="serve_show" >
                <div class="serve_nav" href="#demo">解决方案</div>
                                <a class="serve_nav" href="#serve" style="text-decoration: none;">下载中心</a>
                                <a class="serve_nav" @click="demoClick(index)" style="text-decoration: none;" v-for="(item,index) in SaleData" :key="index">{{ item.title }}</a>
              </div>
            </li>
            <li class="nav_item" @click="relation">联系我们</li>
          </ul>
          
          <div class="present">
            <img src="../../assets/new_IMG/present.gif" alt="" />
          </div>
        </div>
      </div>
      <div class="header_line"></div> -->
      <Header :message="true" :presentLeft="presentLeft" :presentImgLeft="presentImgLeft"></Header>
      <div class="header_bottom" :class="{'home_s':pos_type}">
        <div class="left_circle">
          <div class="circle"></div>
        </div>
        <div class="header_bottom_nav">PANXIANG</div>
        <div class="right_circle">
          <div class="circle"></div>
        </div>
      </div>
      <div class="swiper" :class="{'home_s':pos_type}">
        <div class="swiper_next" @click="next">
          <img src="../../assets/new_IMG/next.png" alt="" />
        </div>
        <div class="swiper_perv" @click="prev">
          <img src="../../assets/new_IMG/prev .png" alt="" />
        </div>
        <div class="swiper_nav">
          <!-- border: 1px solid #f6ad3a -->
          <div class="swiper_img" v-for="(item,index) in CarouselList" :key="index" :style="{border:(index==listIndex?'1px solid #f6ad3a':'none')}" @click="changeClick(item,index)">
            <img :src="`${GloablUrl.baseURL+item.cover_path}`" alt="" />
          </div>
        </div>
      </div>
      <div class="arrows" >
        <img src="../../assets/new_IMG/arrows.png" alt="" />
      </div>
      <div class="big_home">
          <div class="big_logo">
            <div class="box_big_logo" :class="{'css':windowHeight,'home_s':pos_type,'css_s':hart}" > 
              <img src="../../assets/new_IMG/logo_2.png" alt="">
            </div>
          </div>
          <div class="home" :class="{'home_s':pos_type}">
            <video :src="`${GloablUrl.baseURL+URL}`" alt="" muted loop autoplay/>
          </div>
          <div class="big_size" v-if="listIndex == 0">
              <div class="top_size">用科技助力教育，倡导新型教育模式</div>
              <div class="nav_size">安如盘石  相机而动</div>
              <div class="bottom_size">
                <p>西安盘相数字科技有限公司于2017年成立，依靠十三朝古都深厚的文化背景，结合科技化现代理念。</p>
                <p>我们年轻却不莽撞，我们朝气蓬勃兼顾的是成熟与智取，我们用自身的优势，引领的是一个领域的变革。</p>
              </div>
          </div>
      </div>
    </div>
    <div class="border_nav">
      <div class="border_left"></div>
      <div class="or-container">
          <div class="box" :class="eleindex ==index?'eleactive':''" v-for="(item,index) in NewsList" :key="index"  @mouseleave="out(index)" @mouseenter="enter(index)">
            <div class="border_content" v-if="eleindex ==index">
                <div class="content_title">NEWS</div>
                <div class="content_nav">
                  <div class="content_nav_right">
                    <div class="top_icon">
                      <img :src="`${GloablUrl.baseURL+item.cover_img}`" alt="" />
                    </div>
                    <div class="bottom_icon"></div>
                  </div>
                  <div class="content_nav_left">
                    <div class="nav_left_title">{{ item.title }}</div>
                    <div class="nav_left_content">
                      {{ item.content }}
                    </div>
                    <div class="read_more" @click="read_more(item)">read more >></div>
                  </div>

                </div>
              </div>
              <div class="border_small" v-else>
              <div class="small_num">0{{ index+1 }}</div>
              <div class="small_more">
                <div class="more_img">
                  <img src="../../assets/new_IMG/more.png" alt="" />
                </div>
              </div>
      </div>
          </div>
      </div>
    </div>
    <div class="bottom_nav">
      <div class="bottom_header_nav">
        <div class="academy">合作院校</div>
        <div class="line"></div>
        <div class="header_nav" ref="all"   @mouseover="mouseOver" @mouseleave="mouseLeave">
      <!-- 循环数组数据，并绑定ref=demoItem(方便取得循环的每一个元素的最左边位置，并动态添加transform样式) -->
        <div 
              v-for="(item, index) in tableData"
              :key="index"
              class="carousel-item"
              :style="'transform: translateX(' + item.translateNum + 'px)'"
              ref="demoItem"
              >
              <img :src="`${GloablUrl.baseURL+item.img_path}`" alt="">
        </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import  Footer  from '@/components/footer.vue'
import  Header from '@/components/header.vue'
export default {
  //import引入的组件
  components: { Footer,Header },
  data() {
    return {
      presentLeft:3,
      presentImgLeft:21,
      SaleData:[],
      // GloablUrl.baseURL:'http://192.168.0.240:9011/',
      eleindex: 0,
      piclist: [
        {text: '播放/录制页面',  img: 'http://192.168.0.240:9341/present.png'},
        {text: '播放/录制页面',  img: 'http://192.168.0.240:9341/present.png'},
        {text: '播放/录制页面',  img: 'http://192.168.0.240:9341/present.png'},
        {text: '播放/录制页面',  img: 'http://192.168.0.240:9341/present.png'},
      ],
      tableData: [
        {
          name: '张三',
          img:'http://192.168.0.240:9341/school.png'
        }, {
          name: '李四',
          img:'http://192.168.0.240:9341/school.png'
        }, {
          name: '王五',
          img:'http://192.168.0.240:9341/school.png'
        }, {
          name: '赵六',
          img:'http://192.168.0.240:9341/school.png'
        }, {
          name: '钱七',
          img:'http://192.168.0.240:9341/school.png'
        },
        {
          name: '张三',
          img:'http://192.168.0.240:9341/school.png'
        }, {
          name: '李四',
          img:'http://192.168.0.240:9341/school.png'
        }, {
          name: '王五',
          img:'http://192.168.0.240:9341/school.png'
        }, {
          name: '赵六',
          img:'http://192.168.0.240:9341/school.png'
        }, {
          name: '钱七',
          img:'http://192.168.0.240:9341/school.png'
        },
        {
          name: '张三',
          img:'http://192.168.0.240:9341/school.png'
        }, {
          name: '李四',
          img:'http://192.168.0.240:9341/school.png'
        }, {
          name: '王五',
          img:'http://192.168.0.240:9341/school.png'
        }, {
          name: '赵六',
          img:'http://192.168.0.240:9341/school.png'
        }, {
          name: '钱七',
          img:'http://192.168.0.240:9341/school.png'
        }
      ],
      translateNum: 0,
      timer: null,
      windowHeight: false,
      hart:false,
      pos_type:false,
      pop_show:false,
      serve_show:false,
      CarouselList:[],
      NewsList:[],
      CooperateList:[],
      listIndex:0,
      URL:'',
      windowWidth:0,//页面窗口宽度
    };
  },
  watch: {
      windowWidth (val) {
      let that = this;
      that.windowWidth = val
      }
  },
  computed: {},
  //方法集合
  methods: {
    serve(){
            this.$router.push('/serve').catch(err => {console.log(err);})
        },
    demoClick(index){
        localStorage.setItem('anchorId',`demo${index+2}`)
        this.$router.push('/serve').catch(err => {console.log(err);})
      },
    read_more(item){
      if(item.url.match(/(http|https):\/\/\S*/)){
          window.open(item.url)
        }else {
          window.open(`https://${item.url}`)
        }
    },
    product(){
      this.$router.push('/product').catch(err => { console.log(err) });;  
    },
    relation(){
      this.$router.push('/relation').catch(err => { console.log(err) });
      },
    journalism(){
      this.$router.push('/journalism').catch(err => { console.log(err) });
    },
    manpower(){
      this.$router.push('/manpower').catch(err => { console.log(err) });
    },
    culture(){
      this.$router.push('/culture').catch(err => { console.log(err) });
    },
    qualification(){
      this.$router.push('/qualification').catch(err => { console.log(err) });
    },
    showPop(){
      this.pop_show = true
    },
    outPop(){
      this.pop_show = false
    },
    serve_POP_show(){
      this.serve_show = true
    },
    serve_out(){
      this.serve_show = false
    },
    regard(){
      this.$router.push('/about').catch(err => { console.log(err) });
      event.stopPropagation()
    },
    search() {
      // 循环给tableData数组每一个对象添加translateNum属性为0，这也是为了方便记录每一个对象滚动的宽度
      for (var i = 0; i < this.tableData.length; i++) {
        this.$set(this.tableData[i], 'translateNum', 0);
      }
 
      // 在元素完全渲染后再循环给每一个对象添加indexLeft属性，记录此对象初始位置，方便滚动超出父元素边界后，重新设置元素位置
      this.$nextTick(() => {
        for (var j = 0; j < this.tableData.length; j++) {
          this.$set(this.tableData[j], 'indexLeft', this.$refs.demoItem[j].offsetLeft);
        }
 
        // 调用滚动定时器
        this.roll();
      })

    },
    roll() {
      this.timer = setInterval(() => {
        // 循环给每一个对象修改translateNum属性值，从而动态修改页面元素的transform样式，达到滚动的效果
        for (var i = 0; i < this.tableData.length; i++) {
          /**
           * 判断此元素是否即将超出父级元素carousel-item的显示区域
           * 1400 = 父级元素carousel的宽度 + 一个子元素carousel-item的宽度（如果元素有间距也需要带上）即 1200 + 180 + 20(间距) 
           * 修改父级元素与子元素样式时需要留意此处也应当一起修改
           */
          if ((this.windowWidth-280) - this.tableData[i].translateNum - this.tableData[i].indexLeft < 0) {
            /**
             * 如果超出，则将元素移动至父级元素显示区域的左方
             * 此处的200 对应着子元素carousel-item的样式宽度
             */
            this.$set(this.tableData[i], 'translateNum', this.$refs.all.offsetLeft - this.tableData[i].indexLeft-130);
          }
          // 设置每个元素每次滚动的像素大小，像素越小越平滑,这里每次只移动一个像素
          this.$set(this.tableData[i], 'translateNum', this.tableData[i].translateNum + 1);
 
        }
      }, 10); // 30毫秒滚动一次，时间间隔越短滚动越平滑
    },
    mouseOver() {
      window.clearTimeout(this.timer);
    },
    /**
     * 鼠标离开再次执行定时器
     */
    mouseLeave() {
      this.roll();
    },
    enter(index){
      this.eleindex = index
    },
    out(index){
      this.imgindex =  -1
    },
    getCarouselData() {
      this.PX_Get(this.GloablUrl.getCarouselData, (res) => {
        if(res.data.data.length>3){
            this.CarouselList = res.data.data.splice(0,3);
        }else {
            this.CarouselList = res.data.data;
        }
        this.URL = this.CarouselList[0].formal_path
      });
    },
    getMainPageNewsData() {
      this.PX_Get(this.GloablUrl.getMainPageNewsData,(res) => {
        this.NewsList = res.data.data;
      });
    },
    getCooperateData() {
      this.PX_Get(this.GloablUrl.getCooperateData, (res) => {
        this.tableData = res.data.data;
        this.search()
      });
    },
    getTopHeight(){
          let topHeight = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageXOffset)
          // console.log(topHeight);
          if(topHeight > 100) {
            this.windowHeight = true
            this.hart =false
          }else {
            this.hart = true
            this.windowHeight = false
          }
          if(topHeight>400){
            this.pos_type = true
          }else {
            this.pos_type = false
          }
          if(topHeight>450){
            this.black = true
          } else {
            this.black = false
          }
    },
    next(){
      if(this.listIndex == 2) {
          this.listIndex = 2
          this.CarouselList.forEach((item,index) => {
            if(this.listIndex == index) {
              this.URL = item.formal_path
            }
          })
        } else {
          this.listIndex++
          this.CarouselList.forEach((item,index) => {
            if(this.listIndex == index) {
              this.URL = item.formal_path
            }
          })
        }
    },
    prev(){
      if(this.listIndex == 0) {
          this.listIndex = 0
          this.CarouselList.forEach((item,index) => {
            if(this.listIndex == index) {
              this.URL = item.formal_path
            }
          })
        } else {
          this.listIndex--
          this.CarouselList.forEach((item,index) => {
            if(this.listIndex == index) {
              this.URL = item.formal_path
            }
          })
        }
    },
    changeClick(item,index){
      this.URL = item.formal_path
      this.listIndex = index
    },
    getSaleData(){
      this.PX_Get(this.GloablUrl.getSaleData, (res) => {
          this.SaleData = res.data.data
      });
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getCarouselData()
    this.getMainPageNewsData()
    this.getSaleData()
    this.getCooperateData()
    window.addEventListener('scroll', this.getTopHeight , true)
    var that = this;
    //刚进入页面时，获取窗口默认宽高度
    this.windowWidth = document.body.clientWidth
    //根据屏幕缩放自动获取页面宽高
    window.onresize = () => {
      return (() => {
          //窗口缩放自动获取页面宽高
          window.fullWidth = document.documentElement.clientWidth;
          that.windowWidth = window.fullWidth; //宽
          window.clearTimeout(this.timer);
          this.roll()
      })()
    }
  },
  watch:{
      windowWidth (val) {
        let that = this;
        that.windowWidth = val
      }
  },
  beforeDestroy() {
    // 销毁定时器，否则可能导致重载此组件时会有多个定时器同时执行，使得滚动变快
    window.clearTimeout(this.timer);
  },
};
</script>
<style lang="less" scoped>
@font-face {
  font-family: "PangMenZhengDao";
  src: url("../../assets/font-size/庞门正道标题体.ttf");
}
@font-face {
  font-family: "Source Han Sans CN";
  src: url("../../assets/font-size/SourceHanSansCN-Regular.otf");
}
.move {
  border-style: solid;
  border-width: 10px;
  border-radius: 5px;
  border-color: #c7c7c7c7;
}
.home {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
.home_s{
  position: absolute !important;
}
.home > video {
  width: 100%;
  height: 100%;
  object-fit:fill;
}
.top_header {
  width: 100vw;
  height: 100vh;
}
.header {
  width: 100vw;
  height: 70px;
  display: flex;
  padding: 0 40px;
  justify-content: space-around;
  /* 用于定位导航菜单 */
  align-items: center;
  position: absolute;
  padding: 0 60px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  z-index: 999;
}
.header_line {
  width: 100%;
  height: 1px;
  background-color: #f6ad3a;
  position: absolute;
  top: 70px;
  opacity: 0.5;
  z-index: 9;
}
.header_left {
  width: 167px;
  height: 70px;
  position: relative;
}
.left_logo {
  width: 100%;
  height: 32px;
  position: absolute;
  top: 23px;
}
.left_logo > img {
  width: 85%;
  height: 85%;
}
.right_line {
  width: 50px;
  height: 1px;
  background: #f6ad3a;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}
.nav {
  width: 50%;
  height: 70px;
  position: relative;
}
.nav_items {
  width: 100%;
  height: 70px;
  list-style: none;
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  z-index: 99;
}
.nav_item {
  position: relative;
  padding: 20px 0;
  height: 70px;
  box-sizing: border-box;
  cursor: pointer;
}
.box_nav {
  width: 100vw;
  height: 100vh;
  background-color: pink;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav_content {
  width: 80%;
  height: 80%;
  // background-color:aqua;
}
.border_nav {
  width: 100vw;
  height: 100vh;
  background-color: #000;
  display: flex;
}
.border_left {
  width: 119px;
  height: 100%;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
.border_content {
  width: 860px;
  height: 100%;
  padding-left: 48px;
  padding-right: 53px;
  box-sizing: border-box;
  padding-top: 100px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
.border_small {
  width: 208px;
  height: 100%;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  padding: 100px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.content_title {
  width: 121px;
  height: 28px;
  font-size: 35px;
  font-family: PangMenZhengDao;
  font-weight: 400;
  color: #ffffff;
  line-height: 28px;
  opacity: 0.2;
}
.content_title_C{
  width: 121px;
  height: 28px;
  font-size: 35px;
  font-family: PangMenZhengDao;
  font-weight: 400;
  color: #ffffff;
  line-height: 28px;
  margin-top: 55px;
    margin-left: 30px;
  opacity: 0.8;
}
.content_nav {
  width: 100%;
  height: 311px;
  margin-top: 256px;
  display: flex;
  // justify-content:space-around;
}
.content_nav_left {
  width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  // padding-top: 60px;
  box-sizing: border-box;
}
.content_nav_right {
  width: 330px;
  height: 100%;
  position: relative;
  margin-left: -20px;
}
.nav_left_title {
  height: 45px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #f6ad3a;
  line-height: 43px;
  overflow: hidden;
  white-space: nowrap; /* 防止文字换行 */
  text-overflow: ellipsis; /* 超出部分显示省略号 */
}
.nav_left_content {
  width: 400px;
  // height: 93px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 26px;
  margin-top: 20px;
  overflow: hidden; //超出的文本隐藏
  display: -webkit-box;
  -webkit-line-clamp: 3; // 超出多少行
  -webkit-box-orient: vertical;
  // text-indent: 2em;
}
.read_more {
  width: 200px;
  height: 17px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 36px;
  margin-top: 50px;
  cursor: pointer;
}
.read_more:hover{
  color: #f6ad3a;
}
.top_icon {
  width:300px;
  height: 220px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  overflow: hidden;
}
.top_icon > img {
  width: 100%;
  height: 100%;
  transition: all 1s ease;
}
.top_icon>img:hover{
  transform: scale(1.2);
}
.bottom_icon {
  width: 300px;
  height: 220px;
  background-color: #f6ad3a;
  position: absolute;
  top: 15px;
  left: 15px;
}
.small_num {
  width: 100%;
  height: 28px;
  font-size: 35px;
  font-family: PangMenZhengDao;
  font-weight: 400;
  color: #f6ad3a;
  line-height: 28px;
  text-align: center;
}
.small_more {
  width: 100%;
  height: 31px;
}
.more_img {
  width: 31px;
  height: 31px;
  margin: 0 auto;
  cursor: pointer;
  opacity: 0.2;
}
.more_img > img {
  width: 100%;
  height: 100%;
}
.bottom_nav {
  width: 100vw;
}
.bottom_header_nav {
  width: 100%;
  height: 750px;
  background-color: #fff;
  padding-top: 100px;
  box-sizing: border-box;
}
.academy {
  width: 220px;
  height: 52px;
  font-size: 40px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #000000;
  line-height: 52px;
  margin: 0 auto;
  text-align: center;
}
.line {
  width: 80px;
  height: 3px;
  background: #f6ad3a;
  margin: 0 auto;
  margin-top: 15px;
}
.header_nav {
  width: 1478px;
  height: 395px;
  margin: 0 auto;
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
}
.nav_img {
  width: 250px;
  height: 103px;
  margin-bottom: 44px;
  border-radius: 10px;
}
.nav_img > img {
  width: 100%;
  height: 100%;
}
.contact_us {
  width: 565px;
  height: 270px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 160px;
}
.contact_us_title {
  width: 80px;
  height: 19px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 19px;
}
.phone {
  width: 310x;
  height: 61px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 41px;
}
.address {
  width: 80px;
  height: 19px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 19px;
}
.address_nav {
  width: 100%;
  height: 18px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 18px;
}
.cooperation {
  width: 618px;
  height: 250px;
  // background-color: pink;
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.cooperation_left {
  width: 2px;
  height: 220px;
  background: #f6ad3a;
  border-radius: 1px;
}
.cooperation_nav {
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.cooperation_right {
  width: 2px;
  height: 220px;
  background: #f6ad3a;
  border-radius: 1px;
}
.cooperation_title {
  width: 80px;
  height: 19px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 19px;
}
.cooperation_form {
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.name {
  width: 100%;
  height: 36px;
  display: flex;
}
.name_left {
  width: 75px;
  height: 100%;
  background: #f6ad3a;
  border-radius: 0px 0px 0px 6px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 36px;
}
.name_right {
  width: 224px;
  height: 100%;
}
.name_right > input {
  width: 224px;
  height: 100%;
  padding: 0;
  border: 0;
}
.name_right > input:focus {
  outline: none;
  border: none;
}
.realize {
  width: 182px;
  height: 230px;
  margin-top: 20px;
  margin-left: 158px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.realize_header {
  width: 182px;
  height: 182px;
}
.realize_header > img {
  width: 100%;
  height: 100%;
}
.realize_footer {
  width: 80px;
  height: 19px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 19px;
  margin: 0 auto;
}
.present {
  width: 53px;
  height: 20px;
  position: absolute;
  bottom: 0px;
  left:25px
}

.present > img {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: -10px;
  left: 20px;
}
.header_bottom {
  width: 150px;
  height: 20px;
  position: fixed;
  bottom: 18px;
  left: 51px;
  display: flex;
  justify-content: space-between;
  z-index: 9;
}
.left_circle {
  width: 5px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right_circle {
  width: 5px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle {
  width: 5px;
  height: 5px;
  background-color: #f6ad3a;
  border-radius: 50%;
}
.header_bottom_nav {
  width: 100px;
  height: 100%;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 20px;
  color: #ffffff;
}
.swiper {
  width: 800px;
  height: 122px;
  position: fixed;
  bottom: 70px;
  right: -100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9;
}
.swiper_perv {
  width: 65px;
  height: 20px;
  cursor: pointer;
}
.swiper_perv > img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.swiper_next {
  width: 65px;
  height: 20px;
  position: absolute;
  left: 340px;
  cursor: pointer;
}
.swiper_next > img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.swiper_nav {
  width: 730px;
  height: 100%;
  overflow: hidden;
}
.swiper_img {
  width: 220px;
  height: 98%;
  display: inline-block;
  margin-right: 20px;

}
.swiper_img > img {
  width: 100%;
  height: 100%;
  object-fit:fill;
}
.arrows {
  width: 34px;
  height: 34px;
  position: ab;
  bottom: 0;
  left: 50%;
  transform: translateY(-50%);
  z-index: 9;
}
.arrows > img {
  width: 100%;
  height: 100%;
}
.or-container {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  height: 100vh;
}
.or-container:before {
  background-color: rgba(0,0,0,0.4);
}

.box {
  flex: 1;
  overflow: hidden;
  transition: .5s;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
  line-height: 0;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.box > img {
  width: 100%;
  height: calc(100% - 50vh);
  -o-object-fit: cover;
  object-fit: cover;
  transition: .5s;
}

.box > span {
  font-size: 3.8vh;
  display: block;
  text-align: center;
  height: 10vh;
  line-height: 2.6;
}

.eleactive {
  flex: 1 1 30%;
}

.eleactive > img {
  width: 100%;
  height: 100%;
}
.carousel {
  background-color: gray;
  width: 1200px;
  height: 300px;
  margin: 20px auto;
  overflow: hidden;
  display: flex;
  justify-content: left;
}
 
.carousel-item {
  width: 200px;
  height: 103px;
  text-align: center;
  margin: 10px 20px;
  border-radius: 10px;
}
.carousel-item > img {
  width: 100%;
  height: 100%;
}
.box_big_logo{
  width: 100%;
  height:100%;
  position: fixed;
  transform: scale(100);
  transition: transform 0.6s ease;
}
.css{
  transform: scale(1);
  z-index: 9999;
}
.css_s{
  transform: scale(400);
  transition: transform 1.5s ease;
  z-index: 1;
}
.box_big_logo>img{
  width: 100%;
  height: 100%;
}
.big_home{
  width: 100%;
  height: 200vh;
  overflow: hidden;
  background-color: #fff;
  z-index: 99999;
}
.big_logo{
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}
.pop-up{
  width: 145px;
  height: 268px;
  background-color: #fff;
  box-shadow: 0px 0px 8px 0px rgba(23,23,23,0.19);
  opacity: 0.9;
  position: absolute;
  top: 60px;
  left: -25px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.up_nav{
  width: 100%;
  height: 40px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #4E4E4E;
  // color: #F6AD3A;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
.up_nav:hover {
    color: #f6ad3a;
  }
.pop-serve{
  width: 145px;
  height: 220px;
  background-color: #fff;
  box-shadow: 0px 0px 8px 0px rgba(23,23,23,0.19);
  opacity: 0.9;
  position: absolute;
  top: 60px;
  left: -25px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.serve_nav{
  width: 100%;
  height: 40px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #4E4E4E;
  // color: #F6AD3A;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
.serve_nav:hover {
    color: #f6ad3a;
}
.big_size{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-animation: slideInDown 1s;
  margin-top: -120px;
}
.top_size{
  font-family: Source Han Sans CN;
  font-size: 34px;
  color: #fff4c1;
}
.nav_size{
  font-size: 48px;
  font-family: Source Han Sans CN;
  color: #fff;
  padding: 12px 0;
  margin-bottom: 32px;
}
.bottom_size>p{
  font-size: 14px;
  color: #fff4c1;
  font-family: Source Han Sans CN;
  text-align: center;
  height: 21px;
}
</style>