// let baseURL = 'http://192.168.0.240:9011/'
// let baseURL = 'http://192.168.0.158:9000/'  
let baseURL = 'https://manager.pan-xiang.com'  

let getCarouselData = 'getCarouselData'             // 首页-轮播图
let getNewsData = 'getNewsData'                     // 首页-新闻列表
let getCooperateData = 'getCooperateData'           // 首页-合作伙伴
let addMessageData = 'addMessageData'               //新增消息
let getArticleData = 'getArticleData'               //获取文章数据    
let getEvolveData = 'getEvolveData'                 //发展历程       
let getCultureData =    'getCultureData'            //企业文化    
let getRecruitData = 'getRecruitData'               //人力资源 
let getCertificateData = 'getCertificateData';      //认证证书
let getServicesData = 'getServicesData'             //产品与服务数据        
let getServicesDetailData = 'getServicesDetailData'   //获取产品服务详细数据
let getSolutionData = 'getSolutionData'                 //获取解决方案列表
let getSolutionDetailData = 'getSolutionDetailData'     //获取解决方案详细信息
let getSaleData = 'getSaleData'                         //获取售后支持数据
let getDownloadData = 'getDownloadData'                 //获取下载中心列表数据
let getDownloadDetailData = 'getDownloadDetailData'     //获取下载中心详细数据
let getHonourData = 'getHonourData'                     //荣誉资质
let getMainPageNewsData = 'getMainPageNewsData'         // 首页新闻列表   
export default {
    'baseURL':baseURL,
    'getCarouselData':getCarouselData,
    'getNewsData':getNewsData,
    'getCooperateData':getCooperateData,
    'addMessageData':addMessageData,
    'getArticleData':getArticleData,
    'getEvolveData':getEvolveData,
    'getCultureData':getCultureData,
    'getRecruitData':getRecruitData,
    'getServicesData':getServicesData,
    'getServicesDetailData':getServicesDetailData,
    'getCertificateData':getCertificateData,
    'getSolutionData':getSolutionData,
    'getSolutionDetailData':getSolutionDetailData,
    'getSaleData':getSaleData,
    'getDownloadData':getDownloadData,
    'getDownloadDetailData':getDownloadDetailData,
    'getHonourData':getHonourData,
    'getMainPageNewsData':getMainPageNewsData
}