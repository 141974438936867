<template>
    <div class="top_header">
        <div class="header" id="headers">
        <div class="header_left">
          <div class="left_logo">
            <img src="../assets/new_IMG/logo.png" alt="" v-if="message == true" />
            <img src="../assets/new_IMG/logo_h.png" alt="" v-else/>
          </div>
          <div class="right_line"></div>
        </div>
        <div class="nav">
          <ul :class="message == true ?  'nav_items':'nav_items_s'">
            <li class="nav_item" @click="home_page">首页</li>
            <li class="nav_item" @click="regard" @mouseover="showPop"       @mouseleave="outPop">关于盘相
              <div class="pop-up" v-if="pop_show" >
                <div class="up_nav" @click="regard">企业概况</div>
                <div class="up_nav" @click.stop="culture">企业文化</div>
                <div class="up_nav" @click.stop="qualification">资质荣誉</div>
                <div class="up_nav" @click.stop="journalism">新闻动态</div>
                <div class="up_nav" @click.stop="manpower">人力资源</div>
              </div>
            </li>
            <li class="nav_item" @click="product">产品中心</li>
            <li class="nav_item" @click="serve" @mouseover="serve_POP_show"       @mouseleave="serve_out">服务支持
              <div class="pop-serve" v-if="serve_show" >
                <div class="serve_nav" href="#demo">解决方案</div>
                                <a class="serve_nav" href="#serve" style="text-decoration: none;">下载中心</a>
                                <a class="serve_nav" :href="`#serve${index + 2}`" @click="demoClick(index)" style="text-decoration: none;" v-for="(item,index) in SaleData" :key="index">{{ item.title }}</a>
              </div>
            </li>
            <li class="nav_item" @click="relation">联系我们</li>
          </ul>
          
          <div class="present" :style="{left:presentLeft+'%'}">
            <img src="../assets/new_IMG/present.gif" alt="" :style="{left:presentImgLeft+'px'}"/>
          </div>
        </div>
      </div>
      <div class="header_line"></div>
    </div>
</template>

<script>

export default {
//import引入的组件
components: {},
props: ['message','presentLeft','presentImgLeft'],
data() {
return {
    pos_type:false,
    pop_show:false,
    serve_show:false,
    SaleData:[]
};
},
//方法集合
methods: {
    regard(){
      this.$router.push('/about').catch(err => { console.log(err) });
      event.stopPropagation()
    },
    showPop(){
      this.pop_show = true
    },
    outPop(){
      this.pop_show = false
    },
    serve_POP_show(){
      this.serve_show = true
    },
    serve_out(){
      this.serve_show = false
    },
    product(){
      this.$router.push('/product').catch(err => { console.log(err) });;  
    },
    relation(){
      this.$router.push('/relation').catch(err => { console.log(err) });
      },
    journalism(){
      this.$router.push('/journalism').catch(err => { console.log(err) });
    },
    manpower(){
      this.$router.push('/manpower').catch(err => { console.log(err) });
    },
    culture(){
      this.$router.push('/culture').catch(err => { console.log(err) });
    },
    qualification(){
      this.$router.push('/qualification').catch(err => { console.log(err) });
    },
    serve(){
        this.$router.push('/serve').catch(err => {console.log(err);})
    },
    home_page(){
    this.$router.push('/').catch(err => { console.log(err) });
  },
    demoClick(index){
        localStorage.setItem('anchorId',`demo${index+2}`)
        this.$router.push('/serve').catch(err => {console.log(err);})
      },
    getSaleData(){
      this.PX_Get(this.GloablUrl.getSaleData, (res) => {
          this.SaleData = res.data.data
      });
    },
},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {
    this.getSaleData()
},
}
</script>
<style lang="less" scoped>
.top_header {
width: 100vw;
height: 70px;
}
.header {
  width: 100vw;
  height: 70px;
  display: flex;
  padding: 0 40px;
  justify-content: space-around;
  /* 用于定位导航菜单 */
  align-items: center;
  position: absolute;
  padding: 0 60px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  z-index: 999;
}
.header_line {
  width: 100%;
  height: 1px;
  background-color: #f6ad3a;
  position: absolute;
  top: 70px;
  opacity: 0.5;
  z-index: 9;
}
.header_left {
  width: 167px;
  height: 70px;
  position: relative;
}
.left_logo {
  width: 100%;
  height: 32px;
  position: absolute;
  top: 23px;
}
.left_logo > img {
  width: 85%;
  height: 85%;
}
.right_line {
  width: 50px;
  height: 1px;
  background: #f6ad3a;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}
.nav {
  width: 50%;
  height: 70px;
  position: relative;
}
.nav_items {
  width: 100%;
  height: 70px;
  list-style: none;
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  z-index: 99;
}
.nav_items_s{
    width: 100%;
  height: 70px;
  list-style: none;
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #1a1a1a;
  z-index: 99;
}
.nav_item {
  position: relative;
  padding: 20px 0;
  height: 70px;
  box-sizing: border-box;
  cursor: pointer;
}
.pop-up{
  width: 145px;
  height: 268px;
  background-color: #fff;
  box-shadow: 0px 0px 8px 0px rgba(23,23,23,0.19);
  opacity: 0.9;
  position: absolute;
  top: 60px;
  left: -25px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.up_nav{
  width: 100%;
  height: 40px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #4E4E4E;
  // color: #F6AD3A;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
.up_nav:hover {
    color: #f6ad3a;
  }
.pop-serve{
  width: 145px;
  height: 220px;
  background-color: #fff;
  box-shadow: 0px 0px 8px 0px rgba(23,23,23,0.19);
  opacity: 0.9;
  position: absolute;
  top: 60px;
  left: -25px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.serve_nav{
  width: 100%;
  height: 40px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #4E4E4E;
  // color: #F6AD3A;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
.serve_nav:hover {
    color: #f6ad3a;
}
.present {
  width: 53px;
  height: 20px;
  position: absolute;
  bottom: 0px;
}

.present > img {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: -10px;
}
</style>