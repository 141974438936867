
import Vue from 'vue'
// 导入插件
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'index',
        component: resolve => require(['../views/index/index.vue'], resolve)
    },
    {
        path: '/about',
        name: 'about',
        component: resolve => require(['../views/about/index.vue'], resolve)
    },
    {
        path: '/product',
        name: 'product',
        component: resolve => require(['../views/product/index.vue'], resolve)
    },
    {
        path: '/my',
        name: 'my',
        component: resolve => require(['../views/my/index.vue'], resolve)
    },
    {
        path: '/qualification',
        name: 'qualification',
        component: resolve => require(['../views/qualification/index.vue'], resolve)
    },
    {
        path: '/culture',
        name: 'culture',
        component: resolve => require(['../views/culture/index.vue'], resolve)
    },
    {
        path: '/manpower',
        name: 'manpower',
        component: resolve => require(['../views/manpower/index.vue'], resolve)
    },
    {
        path: '/relation',
        name: 'relation',
        component: resolve => require(['../views/relation/index.vue'], resolve)
    },
    {
        path: '/journalism',
        name: 'journalism',
        component: resolve => require(['../views/journalism/index.vue'], resolve)
    },
    {
        path: '/vrdetail',
        name: 'vrdetail',
        component: resolve => require(['../views/VRdetail/index.vue'], resolve),
        // meta:{isPush:true}
    },
    {
        path: '/serve',
        name: 'serve',
        component: resolve => require(['../views/serve/index.vue'], resolve)
    },
    {
        path: '/Solution',
        name: 'Solution',
        component: resolve => require(['../views/Solution/index.vue'], resolve)
    },
    {
        path: '/education',
        name: 'education',
        component: resolve => require(['../views/education/index.vue'], resolve)
    },
    {
        path: '/download',
        name: 'download',
        component: resolve => require(['../views/download/index.vue'], resolve)
    },
    {
        path: '/classroom',
        name: 'classroom',
        component: resolve => require(['../views/classroom/index.vue'], resolve)
    },
    {
        path: '/vraas',
        name: 'vraas',
        component: resolve => require(['../views/vraas/index.vue'], resolve)
    },
    {
        path: '/cloud',
        name: 'cloud',
        component: resolve => require(['../views/cloud/index.vue'], resolve)
    },
]
const router = new VueRouter({
    routes,
    mode: 'hash', //hash  history
    scrollBehavior(to, from, savedPosition) {
        // 期望滚动到哪个位置
        return {
          x: 0,
          y: 0
        }
      },
})



export default router