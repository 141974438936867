<template>
    <div class="chlid_she">
        <div class="dialog">
                <div class="close_button" @click="dialog_close">
                    <img src="../assets/new_IMG/close.png" alt="">
                </div>
                <div class="dialog_nav">
                    信息提交成功！
                </div>
                <div class="dialog_button" @click="dialog_close">
                    确定
                </div>
            </div>
    </div>
</template>

<script>

export default {
//import引入的组件
components: {},
data() {
return {

};
},
//方法集合
methods: {
    dialog_close(){
        this.$emit('dialog_close')
    },
},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {

},
}
</script>

<style lang="less" scoped>
.chlid_she{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background:rgba(0,0,0,0.5);
    z-index: 9999;
}
.dialog{
    width: 600px;
    height: 300px;
    background: #FFFEFD;
    border-radius: 25px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}
.dialog_nav{
    width: 300px;
    height: 33px;
    font-size: 25px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #1A1A1A;
    line-height: 39px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    margin-top: 435px;
    text-align: center;
}
.dialog_button{
    width: 130px;
    height: 36px;
    background: #F6AD3A;
    border-radius: 6px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 36px;
    text-align: center;
    position: absolute;
    bottom: 60px;
    margin: auto;
    left: 0;
    right: 0;
    cursor: pointer;
}
.close_button{
    width: 15px;
    height: 15px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}
.close_button>img{
    width: 100%;
    height: 100%;
}
</style>