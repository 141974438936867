<template>
        <div class="bottom_footer_nav">
        <div class="contact_us">
          <div class="contact_us_title">联系我们</div>
          <div class="phone">
            <div>商务电话：029-89860530</div>
            <div>品牌合作：panxiang@pan-xiang.com</div>
          </div>
          <div class="address">公司地址： 西安市雁塔区延兴门西路755号交大南洋时代A座903室</div>
          <div class="footer_address">
                    <img src="../assets/new_IMG/country.png"  class="left_box_img">
                    <span  class="left_box_span" @click="icp()">陕ICP备20002390号</span>
          </div>
        </div>
        <div class="cooperation">
          <div class="cooperation_left"></div>
          <div class="cooperation_nav">
            <div class="cooperation_title">商务合作</div>
            <div class="cooperation_form">
              <div class="name">
                <div class="name_left">姓名</div>
                <div class="name_right">
                  <input type="text" v-model="name" />
                </div>
              </div>
              <div class="name">
                <div class="name_left">电话</div>
                <div class="name_right">
                  <input type="text" v-model="phone" />
                </div>
              </div>
              <div class="name">
                <div class="name_left">邮箱</div>
                <div class="name_right">
                  <input type="text" v-model="user_email" />
                </div>
              </div>
            </div>
          </div>
          <div class="submit" @click="submit">
                提交
          </div>
          <div class="cooperation_right"></div>
        </div>
        <div class="realize">
          <div class="realize_header">
            <img src="../assets/new_IMG/official.jpg" alt="" />
          </div>
          <div class="realize_footer">关注我们</div>
        </div>
        <Dialog v-if="dialog_show" @dialog_close="dialog_close"></Dialog>
      </div>
      
</template>

<script>
import  Dialog from '@/components/dialog.vue'
export default {
  components: { Dialog },
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      name:'',
      phone:'',
      user_email:'',
      dialog_show:false
    };
  },
  methods:{
    submit(){
        var params = {
          user_name: this.name,
          user_phone: this.phone,
          user_type:'',
          user_message:'',
          user_email:this.user_email,
          type:1,
          user_company:'',
          user_address:''
        };
        this.PX_Post(this.GloablUrl.addMessageData, params, (res) => {
            if(res.data.code == 200) {
              this.dialog_show = true
              this.name = ''
              this.phone = ''
              this.user_email = ''
            }
        });
    },
    dialog_close(){
            this.dialog_show = false
    },
    overTwo(){
          const html = window.document.getElementsByTagName('html')[0];
          html.style.overflow = 'initial';
    },
    icp(){
               window.open('https://beian.miit.gov.cn', '_blank');
    },
  },
  mounted(){
    this.overTwo()
  }
}
</script>
<style lang="less" scoped>
.bottom_footer_nav {
  width: 100%;
  height: 300px;
  background-color: #000;
  padding: 0 120px;
  box-sizing: border-box;
  display: flex;
}
.academy {
  width: 220px;
  height: 52px;
  font-size: 55px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #000000;
  line-height: 52px;
  margin: 0 auto;
}
.line {
  width: 80px;
  height: 3px;
  background: #f6ad3a;
  margin: 0 auto;
  margin-top: 30px;
}
.header_nav {
  width: 1478px;
  height: 395px;
  margin: 0 auto;
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
}
.nav_img {
  width: 250px;
  height: 103px;
  margin-bottom: 44px;
  border-radius: 10px;
}
.nav_img > img {
  width: 100%;
  height: 100%;
}
.contact_us {
  width: 565px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 75px;
}
.contact_us_title {
  width: 80px;
  height: 19px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 0px;
  opacity: 0.8;
}
.phone {
  width: 310x;
  height: 61px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 41px;
  margin-top: -20px;
  opacity: 0.8;
}
.address {
  width: 100%;
  height: 19px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 40px;
  opacity: 0.8;
}
.address_nav {
  width: 100%;
  height: 18px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 18px;
}
.cooperation {
  width: 618px;
  height: 166px;
  // background-color: pink;
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin: auto;
}
.cooperation_left {
  width: 2px;
  height: 174px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 1px;
}
.cooperation_nav {
  width: 257px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.cooperation_right {
  width: 2px;
  height: 174px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 1px;
}
.cooperation_title {
  // width: 80px;
  height: 18px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 19px;
  margin-bottom: 15px;
  opacity: 0.8;
}
.cooperation_form {
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.name {
  width: 100%;
  height: 30px;
  display: flex;
}
.name_left {
  width: 75px;
  height: 100%;
  background: #f6ad3a;
  border-radius: 0px 0px 0px 6px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 30px;
}
.name_right {
  width: 193px;
  height: 100%;
}
.name_right > input {
  width: 193px;
  height: 100%;
  padding: 0;
  border: 0;
}
.name_right > input:focus {
  outline: none;
  border: none;
}
.realize {
  width: 100px;
  height: 130px;
  margin-top: 80px;
  margin-left: 158px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.realize_header {
  width: 100px;
  height: 100px;
}
.realize_header > img {
  width: 100%;
  height: 100%;
}
.realize_footer {
  // width: 80px;
  height: 19px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.8;
  line-height: 19px;
  margin: 0 auto;
}
.submit{
  width: 86px;
  height: 31px;
  background: #F6AD3A;
  border-radius: 6px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 31px;
  text-align: center;
  cursor: pointer;
  margin-left: -100px;
}
.left_box_img_two{
    margin-left: 20px;
}
.left_box_span{
    margin-left: 8px;
    vertical-align: middle;
    cursor: pointer;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 19px;
    color: #ffffff;
    opacity: 0.8;
}
.footer_address{
  widows: 100%;
  height: 19px;
  display: flex;
  align-content: center;
}
</style>
